import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {
  SelectChangeEvent,
  DialogContent,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Point, PointType, POINT_TYPES } from '../../data/types';
import { ContestantSelector } from '../admin/ContestantSelector';

interface SelectContestModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddPointsModal = (props: SelectContestModalProps) => {
  const { contestants, addPoints } = useContext(DataContext);
  const [contestantIds, setSelectedContestantIds] = useState<string[]>([]);
  const [pointType, setPointType] = useState<PointType>(POINT_TYPES[0]);
  const [loading, setLoading] = useState(false);
  const [episode, setEpisode] = useState<number | null>(1);

  const handleEpisodeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEpisode(parseInt(ev.target.value) || null);
  };

  const handlePointTypeChange = (ev: SelectChangeEvent<PointType>) => {
    setPointType(ev.target.value as PointType);
  };

  const add = async () => {
    if (!episode) {
      return;
    }
    setLoading(true);
    const points: Point[] = contestantIds.map((contestantId) => ({
      contestantId,
      episodeNumber: episode,
      type: pointType.type,
      value: pointType.value,
    }));
    await addPoints(points);
    props.onClose();
    setLoading(false);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>What happened?</DialogTitle>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <DialogContent>
            <TextField
              fullWidth
              value={episode}
              label="Episode"
              variant="filled"
              type="number"
              onChange={handleEpisodeChange}
            />
            <FormControl fullWidth variant="filled">
              <InputLabel id="point-type">Point type</InputLabel>
              <Select labelId="point-type" value={pointType} onChange={handlePointTypeChange}>
                {POINT_TYPES.map((type) => (
                  <MenuItem value={type as any} key={type.type}>
                    {type.type} ({type.value})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ContestantSelector contestants={contestants} onChange={setSelectedContestantIds} value={contestantIds} />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => props.onClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={add} disabled={!episode}>
              Add points
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
