export interface IdentifiableObject {
  id: string;
}

export interface Person extends IdentifiableObject {
  firstName: string;
  lastName: string;
  image: string;
}

export interface Contestant extends Person {
  tribeName: string;
  eliminated?: number;
  advantages?: Tag[];
}

export interface RankedObject {
  totalPoints: number;
  place: number;
}

export interface AggregatedContestant extends Contestant, RankedObject {
  points: Point[];
}

export interface AggregatedUser extends User, RankedObject {
  contestants: AggregatedContestant[];
}

export interface User extends Person {
  identity: string;
  admin: boolean;
  contestantIds: string[];
}

export interface Point extends Partial<IdentifiableObject> {
  episodeNumber: number;
  contestantId: string;
  type: string;
  value: number;
}

export interface PointType {
  type: string;
  value: number;
}

export const POINT_TYPES: PointType[] = [
  {
    type: 'SURVIVED',
    value: 5,
  },
  {
    type: 'FOUND_IDOL',
    value: 10,
  },
  {
    type: 'ADVANTAGE',
    value: 5,
  },
  {
    type: 'VOTE_FOR',
    value: 10,
  },
  {
    type: 'WINNER',
    value: 25,
  },
  {
    type: 'INDIVIDUAL_REWARD',
    value: 3,
  },
  {
    type: 'TRIBE_REWARD',
    value: 1,
  },
  {
    type: 'TRIBE_IMMUNITY',
    value: 3,
  },
  {
    type: 'INDIVIDUAL_IMMUNITY',
    value: 5,
  },
];

export enum Tag {
  IDOL = 'IDOL',
  EXTRA_VOTE = 'EXTRA_VOTE',
  STEAL_VOTE = 'STEAL_VOTE',
}
