import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Avatar, DialogContent, TextField, Typography } from '@mui/material';
import groupBy from 'lodash/groupBy';
import { AggregatedContestant, Contestant, Tag } from '../../data/types';
import { getTypeDescriptor } from '../../utils/points';
import { Box } from '@mui/system';
import { TribeInfo } from '../TribeInfo';
import { AdditionalInfo } from '../AdditionalInfo';
import { TagList } from '../TagList';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';

interface ContestantModalProps {
  open: boolean;
  onClose: () => void;
  contestant: AggregatedContestant;
}

export const ContestantModal = (props: ContestantModalProps) => {
  const { contestant } = props;
  const [editMode, setEditMode] = useState(false);
  const [advantages, setAdvantages] = useState('');
  const { isAdmin } = useContext(AuthContext);
  const { updateContestant } = useContext(DataContext);

  const name = `${contestant.firstName} ${contestant.lastName}`;

  const pointsByEpisode = groupBy(contestant.points, (p) => p.episodeNumber);
  const episodes = Object.keys(pointsByEpisode).sort((a, b) => parseInt(a) - parseInt(b));
  const eliminated = !!contestant.eliminated;

  const saveAdvantages = async () => {
    const c: Partial<Contestant> = {
      id: contestant.id,
      advantages: advantages ? (advantages.split(',') as Tag[]) : [],
    };
    await updateContestant(c);
    setEditMode(false);
    setAdvantages('');
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar
          alt={name}
          src={contestant.image}
          sx={{ width: 200, height: 200, filter: eliminated ? 'grayscale(100%)' : 'none', mb: 2 }}
        />
        <TribeInfo noPadding contestant={contestant} />
        {!editMode && <TagList tags={contestant.advantages} />}
        {editMode && (
          <>
            <TextField
              sx={{ mt: 1.5 }}
              label="Current advantages:"
              helperText="Comma separated. Options: EXTRA_VOTE,IDOL"
              onChange={(ev) => setAdvantages(ev.target.value)}
            />
          </>
        )}
        <AdditionalInfo contestant={contestant} />
        {episodes.map((episodeNumber: string) => (
          <Box sx={{ width: '100%' }} key={episodeNumber}>
            <Typography sx={{ fontWeight: 600, mt: 2 }}>Episode #{episodeNumber}</Typography>
            {pointsByEpisode[episodeNumber].map((point, index) => (
              <Typography key={index} sx={{ fontStyle: 'italic' }}>
                {getTypeDescriptor(point)}
              </Typography>
            ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => props.onClose()}>
          Close
        </Button>
        {isAdmin && (
          <Button color="info" onClick={() => (editMode ? saveAdvantages() : setEditMode(true))}>
            {editMode ? 'Save' : 'Edit'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
