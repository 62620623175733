// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import 'web-vitals/base';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBErEkV3_K943O-w7h3_qZL5cuCMxxkWbk',
  authDomain: 'danm-co.firebaseapp.com',
  projectId: 'danm-co',
  storageBucket: 'danm-co.appspot.com',
  messagingSenderId: '446302835597',
  appId: '1:446302835597:web:b9c632d1b2aea2314ea3c4',
  measurementId: 'G-MJCCN95L7Y',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getPerformance(app);
