import { ReactNode, useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, User } from 'firebase/auth';

import { AuthContext } from '../contexts/AuthContext';
import { ApiProvider } from './ApiProvider';

interface AuthProps {
  children: ReactNode;
}

const auth = getAuth();
const provider = new GoogleAuthProvider();

export const AuthProvider = (props: AuthProps) => {
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState('');

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (user) => {
      setToken((await user?.getIdToken()) || '');
      setUser(user);
      setInitialized(true);
    });
    return subscriber;
  });

  const signIn = async () => {
    const result = await signInWithPopup(auth, provider);
    const idToken = await result.user.getIdToken();
    setToken(idToken);
  };

  const signOut = async () => {
    await auth.signOut();
    setUser(null);
    setIsAdmin(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        initialized,
        signIn,
        signOut,
        isAdmin,
        setIsAdmin,
      }}
    >
      <ApiProvider token={token}>{props.children}</ApiProvider>
    </AuthContext.Provider>
  );
};
