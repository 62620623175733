// side effects
import './config/firebase';

import { Header } from './components/Header';
import { BrowserRouter } from 'react-router-dom';
import Router from './components/Router';
import { AuthProvider } from './components/AuthProvider';
import { DataProvider } from './components/DataProvider';

import './App.css';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <DataProvider>
          <BrowserRouter>
            <Header />
            <Router />
          </BrowserRouter>
        </DataProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
