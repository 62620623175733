import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent, DialogContent, CircularProgress, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Contestant } from '../../data/types';

interface SelectContestModalProps {
  open: boolean;
  onClose: () => void;
}

export const EliminateModal = (props: SelectContestModalProps) => {
  const { contestants, updateContestant } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [selectedContestant, setSelectedContestant] = useState<Contestant | undefined>(contestants[0]);
  const [episode, setEpisode] = useState<number | null>(1);

  const handleEpisodeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEpisode(parseInt(ev.target.value) || null);
  };

  const handleContestantChange = (ev: SelectChangeEvent<Contestant>) => {
    setSelectedContestant(ev.target.value as Contestant);
  };

  const eliminate = async () => {
    if (!selectedContestant || !episode) {
      return;
    }
    setLoading(true);
    const newContestant = {
      ...selectedContestant,
      eliminated: episode,
    };
    await updateContestant(newContestant);
    props.onClose();
    setLoading(false);
    setSelectedContestant(contestants[0]);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Who got voted out?</DialogTitle>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <DialogContent>
            <FormControl fullWidth variant="filled">
              <InputLabel id="eliminate-contestant">Contestant</InputLabel>
              <Select
                labelId="eliminate-contestant"
                value={selectedContestant}
                onChange={handleContestantChange}
                defaultValue={contestants[0]}
              >
                {contestants.map((contestant) => (
                  <MenuItem value={contestant as any} key={contestant.id}>
                    {contestant.firstName} {contestant.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              value={episode}
              label="Episode"
              variant="filled"
              type="number"
              onChange={handleEpisodeChange}
            />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => props.onClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={eliminate} disabled={!episode}>
              Eliminate
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
