import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { AuthContext } from '../contexts/AuthContext';

import { IconButton, Popover } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GoogleButton from 'react-google-button';
import { UserSettingsModal } from './modals/UserSettingsModal';

export const HeaderUser = () => {
  const { signIn, user, initialized } = useContext(AuthContext);
  const [loginAnchor, setLoginAnchor] = useState<HTMLButtonElement | null>(null);
  const [settingsAnchor, setSettingsAnchor] = useState<HTMLButtonElement | null>(null);

  const handleLoginClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setLoginAnchor(event.currentTarget);
  };

  const handleSettingsClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setLoginAnchor(null);
    setSettingsAnchor(null);
  };

  useEffect(() => {
    handleClose();
  }, [user]);

  if (!initialized) {
    return <CircularProgress variant="indeterminate" color="info" size={20} />;
  } else if (!user) {
    return (
      <>
        <IconButton sx={{ color: 'white' }} color="inherit" onClick={handleLoginClick} aria-label="Sign in">
          <PersonIcon />
        </IconButton>
        <Popover
          key="login"
          open={!!loginAnchor}
          anchorEl={loginAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="sign-in-popover">
            <GoogleButton onClick={signIn} />
          </div>
        </Popover>
      </>
    );
  } else {
    return (
      <>
        <IconButton sx={{ color: 'white' }} color="inherit" onClick={handleSettingsClick} aria-label="Settings">
          <PersonIcon />
        </IconButton>
        <Popover
          key="settings"
          open={!!settingsAnchor}
          anchorEl={settingsAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <UserSettingsModal />
        </Popover>
      </>
    );
  }
};
