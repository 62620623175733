import { Tag } from '../data/types';
import { HowToVote, Security, CopyAll } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';

function getIcon(tag: Tag) {
  switch (tag) {
    case Tag.EXTRA_VOTE:
      return HowToVote;
    case Tag.STEAL_VOTE:
      return CopyAll;
    case Tag.IDOL:
    default:
      return Security;
  }
}

function getDescriptor(tag: Tag) {
  switch (tag) {
    case Tag.EXTRA_VOTE:
      return 'Extra vote';
    case Tag.STEAL_VOTE:
      return 'Steal a vote';
    case Tag.IDOL:
    default:
      return 'Hidden immunity idol';
  }
}

interface TagListProps {
  tags?: Tag[];
}

export const TagList = ({ tags }: TagListProps) => {
  if (!tags || tags.length === 0) {
    return null;
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5 }}>
      {tags.map((tag) => {
        const Icon = getIcon(tag);
        return (
          <Tooltip placement="bottom" title={getDescriptor(tag)}>
            <Icon color="info" sx={{ height: 30, width: 30 }} />
          </Tooltip>
        );
      })}
    </Box>
  );
};
