import { List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { useHistory } from 'react-router-dom';

import SurvivorLogo from '../assets/survivor.png';

export const MainPage = () => {
  const history = useHistory();

  return (
    <List>
      <ListItemButton sx={{ alignItems: 'center' }} onClick={() => history.push('/survivor')}>
        <ListItemAvatar>
          <img alt="Survivor logo" src={SurvivorLogo} width="100px" />
        </ListItemAvatar>
        <ListItemText
          sx={{ ml: 2 }}
          primary="Fantasy Survivor"
          secondary="Check scores and point breakdowns by player"
        />
      </ListItemButton>
    </List>
  );
};
