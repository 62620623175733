import { NavLink } from 'react-router-dom';
import { HeaderUser } from './HeaderUser';
import './Header.css';

export const Header = () => {
  return (
    <header className="header">
      <NavLink to="/" className="name">
        DAN MCKITTRICK
      </NavLink>
      <ul>
        <li>
          <NavLink to="/survivor" className="nav-link" activeClassName="nav-link-active">
            Survivor
          </NavLink>
        </li>
      </ul>
      <HeaderUser />
    </header>
  );
};
