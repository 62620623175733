import { Switch, Route } from 'react-router-dom';
import { MainPage } from '../pages/MainPage';
import { SurvivorPage } from '../pages/SurvivorPage';

export default function Router() {
  return (
    <Switch>
      <Route path="/survivor">
        <SurvivorPage />
      </Route>
      <Route path="*">
        <MainPage />
      </Route>
    </Switch>
  );
}
