import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { AggregatedContestant } from '../data/types';
import { TribeInfo } from './TribeInfo';

import './ContestantInfo.css';
import { useState } from 'react';
import { ContestantModal } from './modals/ContestantModal';

interface ContestantInfoProps {
  contestant: AggregatedContestant;
}

export const ContestantInfo = (props: ContestantInfoProps) => {
  const { contestant } = props;
  const eliminated = !!contestant.eliminated;
  const contestantName = `${contestant.firstName} ${contestant.lastName}`;
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItemButton alignItems="flex-start" sx={{ pl: 8, alignItems: 'center' }} onClick={() => setOpen(true)}>
        <ListItemAvatar>
          <Avatar
            alt={contestantName}
            src={contestant.image}
            sx={{ width: 60, height: 60, mr: 2, filter: eliminated ? 'grayscale(100%)' : 'none' }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <div className="contestant-info-name">
              {contestantName}
              <TribeInfo contestant={contestant} />
            </div>
          }
          secondary={`${contestant.totalPoints} points`}
        />
      </ListItemButton>
      <ContestantModal open={open} contestant={contestant} onClose={() => setOpen(false)} />
    </>
  );
};
