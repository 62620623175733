import { Contestant, Point, User } from './types';

export class Api {
  private static BASE_URL = 'https://us-east4-danm-co.cloudfunctions.net/api/v1/survivor';
  private static CONTESTANTS_URL = `${Api.BASE_URL}/contestants`;
  private static USERS_URL = `${Api.BASE_URL}/users`;
  private static POINTS_URL = `${Api.BASE_URL}/points`;

  private token: string = '';

  public setToken(token: string) {
    this.token = token;
  }

  public async getContestants(): Promise<Contestant[]> {
    return this.get(Api.CONTESTANTS_URL);
  }

  public async getUsers(): Promise<User[]> {
    return this.get(Api.USERS_URL);
  }

  public async getPoints(): Promise<Point[]> {
    return this.get(Api.POINTS_URL);
  }

  public async getAuthenticatedUser(): Promise<User> {
    return this.get(`${Api.USERS_URL}/me`);
  }

  public async updateContestant(contestant: Partial<Contestant>): Promise<Contestant> {
    return this.post(Api.CONTESTANTS_URL, contestant);
  }

  public async updateUser(user: User): Promise<User> {
    return this.post(Api.USERS_URL, user);
  }

  public async addPoints(points: Point[]): Promise<Point[]> {
    return this.post(Api.POINTS_URL, points);
  }

  private async get(url: string) {
    const result = await fetch(url, {
      headers: this.getHeaders(),
    });
    const response = await result.json();
    return response;
  }

  private async post(url: string, data: object) {
    const result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        ...this.getHeaders(),
        'Content-Type': 'application/json',
      },
    });
    const response = await result.json();
    return response;
  }

  private getHeaders(): HeadersInit {
    if (!this.token) {
      return {};
    }
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }
}
