import { createContext } from 'react';
import { AggregatedContestant, AggregatedUser, Contestant, Point, User } from '../data/types';

interface DataContextData {
  users: User[];
  contestants: Contestant[];
  points: Point[];
  aggregatedUsers: AggregatedUser[];
  aggregatedContestants: AggregatedContestant[];
  undraftedContestants: AggregatedContestant[];
  updateContestant: (contestant: Partial<Contestant>) => void;
  updateUser: (user: User) => void;
  addPoints: (points: Point[]) => void;
}

export const DataContext = createContext<DataContextData>({
  contestants: [],
  aggregatedContestants: [],
  undraftedContestants: [],
  users: [],
  aggregatedUsers: [],
  points: [],
  updateContestant: () => null,
  updateUser: () => null,
  addPoints: () => null,
});
