import groupBy from 'lodash/groupBy';
import { AggregatedContestant, AggregatedUser, Contestant, Point, RankedObject, User } from '../data/types';

export function aggregatePointsForContestants(contestants: Contestant[], points: Point[]) {
  const pointsByContestantId = groupBy(points, 'contestantId');
  const aggContestants: AggregatedContestant[] = contestants.map((contestant) => {
    const contestantsPoints = pointsByContestantId[contestant.id] || [];
    return {
      ...contestant,
      points: contestantsPoints,
      totalPoints: sumOfPoints(contestantsPoints),
      place: -1,
    };
  });
  return sortAndRank(aggContestants);
}

export function aggregatePointsForUsers(contestants: AggregatedContestant[], users: User[]) {
  const aggUsers: AggregatedUser[] = users.map((user) => {
    const relevantContestants = (user.contestantIds || [])
      .map((id: string) => contestants.find((c) => c.id === id) as AggregatedContestant)
      .filter((item) => !!item);
    return {
      ...user,
      totalPoints: relevantContestants.reduce((prev, current) => prev + current.totalPoints, 0),
      place: -1,
      contestants: relevantContestants,
    };
  });
  return sortAndRank(aggUsers);
}

export function getPlaceName(place: number) {
  let suffix = 'th';
  if (place === 1) {
    suffix = 'st';
  } else if (place === 2) {
    suffix = 'nd';
  } else if (place === 3) {
    suffix = 'rd';
  }
  // we only have 12 players, 18 contestants - works for that
  return `${place}${suffix} place`;
}

export function getTypeDescriptor(point: Point) {
  return `${getTypeDescriptorPrefix(point)} (${point.value} point${point.value === 1 ? '' : 's'})`;
}

function getTypeDescriptorPrefix(point: Point) {
  switch (point.type) {
    case 'SURVIVED':
      return 'Survived the week';
    case 'FOUND_IDOL':
      return 'Found a hidden immunity idol';
    case 'ADVANTAGE':
      return 'Got a hidden advantage';
    case 'VOTE_FOR':
      return 'Received a vote for';
    case 'WINNER':
      return 'Won!';
    case 'INDIVIDUAL_REWARD':
      return 'Received an individual reward';
    case 'TRIBE_REWARD':
      return 'Received a tribe reward';
    case 'TRIBE_IMMUNITY':
      return 'Received tribal immunity';
    case 'INDIVIDUAL_IMMUNITY':
      return 'Received individual immunity';
    default:
      return point.type;
  }
}

function sortAndRank<T extends RankedObject>(objects: T[]) {
  const newArr = [...objects];
  newArr.sort((a, b) => {
    return b.totalPoints - a.totalPoints;
  });
  let previousTotal = -1;
  let place = 0;
  let trackingPlace = 0;

  newArr.forEach((object) => {
    trackingPlace++;
    if (object.totalPoints === previousTotal) {
      object.place = place;
    } else {
      object.place = trackingPlace;
      place = trackingPlace;
    }
    previousTotal = object.totalPoints;
  });
  return newArr;
}

function sumOfPoints(points: Point[]) {
  return points.reduce((previous, current) => {
    return previous + current.value;
  }, 0);
}
