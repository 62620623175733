import { Contestant } from '../data/types';
import sydney from '../assets/sydney.png';
import eric from '../assets/eric.png';

interface AdditionalInfoProps {
  contestant: Contestant;
}

export const AdditionalInfo = (props: AdditionalInfoProps) => {
  switch (props.contestant.firstName) {
    case 'Sydney':
      return (
        <img
          src={sydney}
          style={{ maxWidth: '90%' }}
          alt="Tim eternalizing Ben's chirp on his pick of Sydney, in hopes of vindication"
        />
      );
    case 'Eric':
      return <img src={eric} style={{ maxWidth: '90%' }} alt="Ben calling it, Spence going a different direction." />;
    default:
      return null;
  }
};
