import { Contestant } from '../data/types';
import './TribeInfo.css';

interface TribeInfoProps {
  contestant: Contestant;
  noPadding?: boolean;
}

export const TribeInfo = ({ contestant, noPadding }: TribeInfoProps) => {
  const tribe = contestant.eliminated ? 'Eliminated' : contestant.tribeName;
  const className = `tribe-info-wrapper ${noPadding ? 'tribe-info-wrapper--no-padding' : ''}`;
  return (
    <div className={className}>
      <div className={`tribe-info tribe-info-${tribe}`}>{tribe}</div>
    </div>
  );
};
