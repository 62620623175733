import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { Api } from '../data/api';
import { ApiContext } from '../contexts/ApiContext';
import { AuthContext } from '../contexts/AuthContext';

interface ApiProviderProps {
  token: string;
  children: React.ReactNode;
}

const api = new Api();

export const ApiProvider = (props: ApiProviderProps) => {
  const { setIsAdmin } = useContext(AuthContext);
  const ref = useRef<Api>(api);

  const checkAdminStatus = useCallback(async () => {
    try {
      const authUser = await ref.current.getAuthenticatedUser();
      setIsAdmin(!!authUser.admin);
    } catch (err) {
      setIsAdmin(false);
    }
  }, [setIsAdmin]);

  useEffect(() => {
    ref.current.setToken(props.token);
    if (props.token) {
      checkAdminStatus();
    }
  });

  return <ApiContext.Provider value={ref.current}>{props.children}</ApiContext.Provider>;
};
