import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import flatten from 'lodash/flatten';
import { useMemo } from 'react';
import { Contestant } from '../../data/types';

interface TribeMap {
  [name: string]: Contestant[];
}

interface ContestantSelectorProps {
  contestants: Contestant[];
  value: string[];
  onChange: (value: string[]) => void;
}

export const ContestantSelector = (props: ContestantSelectorProps) => {
  const { contestants, value, onChange } = props;

  const { eliminated, tribes } = useMemo(() => {
    const elim: Contestant[] = [];
    const otherTribes: TribeMap = {};

    contestants.forEach((contestant) => {
      if (contestant.eliminated) {
        elim.push(contestant);
      } else if (otherTribes[contestant.tribeName]) {
        otherTribes[contestant.tribeName].push(contestant);
      } else {
        otherTribes[contestant.tribeName] = [contestant];
      }
    });

    return {
      eliminated: elim,
      tribes: otherTribes,
    };
  }, [contestants]);

  const handleChangeBuilder = (id: string) => {
    return () => {
      const index = value.indexOf(id);
      if (index >= 0) {
        onChange(value.slice(0, index).concat(value.slice(index + 1)));
      } else {
        onChange([...value, id]);
      }
    };
  };

  const grouping = (name: string, cons: Contestant[]) => {
    return (
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" key={name}>
        <FormLabel component="legend">{name}</FormLabel>
        <FormGroup>
          {cons.map((contestant) => (
            <FormControlLabel
              control={
                <Checkbox checked={value.indexOf(contestant.id) >= 0} onChange={handleChangeBuilder(contestant.id)} />
              }
              label={`${contestant.firstName} ${contestant.lastName}`}
              key={contestant.id}
            />
          ))}
        </FormGroup>
        <FormHelperText>
          <Button onClick={() => onChange(cons.map((c) => c.id))}>Select all {name}</Button>
        </FormHelperText>
      </FormControl>
    );
  };

  return (
    <div>
      <Typography sx={{ marginTop: '10px' }} variant="h6">
        Contestants
      </Typography>
      <div>
        <Button onClick={() => onChange(flatten(Object.values(tribes)).map((c) => c.id))}>Select all active</Button>
        <Button color="error" onClick={() => onChange([])}>
          Clear all
        </Button>
      </div>
      <div>
        {Object.keys(tribes).map((tribeName) => grouping(tribeName, tribes[tribeName]))}
        {grouping('Eliminated', eliminated)}
      </div>
    </div>
  );
};
