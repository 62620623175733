import { User } from 'firebase/auth';
import { createContext } from 'react';

interface AuthContextData {
  user: User | null;
  initialized: boolean;
  isAdmin: boolean;
  signIn: () => void;
  signOut: () => void;
  setIsAdmin: (isAdmin: boolean) => void;
}

export const AuthContext = createContext<AuthContextData>({
  user: null,
  initialized: false,
  signIn: () => null,
  signOut: () => null,
  isAdmin: false,
  setIsAdmin: () => null,
});
