import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { AdminActions } from '../components/admin/AdminActions';
import { UserStandings } from '../components/UserStandings';
import { AuthContext } from '../contexts/AuthContext';
import { DataContext } from '../contexts/DataContext';

export const SurvivorPage = () => {
  const { contestants } = useContext(DataContext);
  const { isAdmin } = useContext(AuthContext);

  if (contestants.length === 0) {
    return <CircularProgress />;
  }

  return (
    <>
      {isAdmin && <AdminActions />}
      <UserStandings />
    </>
  );
};
