import React, { useContext, useEffect, useMemo, useState } from 'react';
import flatten from 'lodash/flatten';
import { ApiContext } from '../contexts/ApiContext';
import { DataContext } from '../contexts/DataContext';
import { Contestant, IdentifiableObject, Person, Point, User } from '../data/types';
import { aggregatePointsForContestants, aggregatePointsForUsers } from '../utils/points';

interface DataProviderProps {
  children: React.ReactNode;
}

function byFirstName<T extends Person>(a: T, b: T) {
  return a.firstName > b.firstName ? 1 : -1;
}

function patchList<T extends IdentifiableObject>(list: T[], item: Partial<T>) {
  return list.map((entry) => (entry.id === item.id ? { ...entry, ...item } : entry));
}

export const DataProvider = (props: DataProviderProps) => {
  const api = useContext(ApiContext);
  const [points, setPoints] = useState<Point[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [contestants, setContestants] = useState<Contestant[]>([]);

  const addPoints = async (newPoints: Point[]) => {
    await api.addPoints(newPoints);
    setPoints(points.concat(newPoints));
  };

  const updateContestant = async (contestant: Partial<Contestant>) => {
    await api.updateContestant(contestant);
    setContestants(patchList(contestants, contestant));
  };

  const updateUser = async (user: User) => {
    await api.updateUser(user);
    setUsers(patchList(users, user));
  };

  const fetchAllData = async () => {
    const [allUsers, allContestants, allPoints] = await Promise.all([
      api.getUsers(),
      api.getContestants(),
      api.getPoints(),
    ]);
    setUsers(allUsers.sort(byFirstName));
    setContestants(allContestants.sort(byFirstName));
    setPoints(allPoints);
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [aggregatedUsers, aggregatedContestants, undraftedContestants] = useMemo(() => {
    const aggContestants = aggregatePointsForContestants(contestants, points);
    const aggUsers = aggregatePointsForUsers(aggContestants, users);
    const draftedContestantIds = flatten(users.map((user) => user.contestantIds || []));
    const undrafted = aggContestants.filter((contestant) => draftedContestantIds.indexOf(contestant.id) === -1);
    return [aggUsers, aggContestants, undrafted];
  }, [contestants, users, points]);

  return (
    <DataContext.Provider
      value={{
        users,
        points,
        contestants,
        aggregatedContestants,
        aggregatedUsers,
        undraftedContestants,
        addPoints,
        updateContestant,
        updateUser,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
