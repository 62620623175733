import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AggregatedUser } from '../data/types';
import { getPlaceName } from '../utils/points';

interface UserInfoProps {
  user: AggregatedUser;
}

export const UserInfo = (props: UserInfoProps) => {
  const { user } = props;
  const name = `${user.firstName} ${user.lastName}`;
  return (
    <ListItem alignItems="flex-start" sx={{ alignItems: 'center' }}>
      <ListItemAvatar>
        <Avatar alt={name} src={user.image} sx={{ width: 60, height: 60, mr: 2 }} />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={getPlaceName(user.place)} />
    </ListItem>
  );
};
