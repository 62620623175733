import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent, DialogContent, CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Contestant, User } from '../../data/types';

interface SelectContestModalProps {
  open: boolean;
  onClose: () => void;
}

export const SelectContestantModal = (props: SelectContestModalProps) => {
  const { users, contestants, updateUser } = useContext(DataContext);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(users[0]);
  const [loading, setLoading] = useState(false);
  const [selectedContestant, setSelectedContestant] = useState<Contestant | undefined>(contestants[0]);

  const handleUserChange = (ev: SelectChangeEvent<User>) => {
    setSelectedUser(ev.target.value as User);
  };

  const handleContestantChange = (ev: SelectChangeEvent<Contestant>) => {
    setSelectedContestant(ev.target.value as Contestant);
  };

  const pair = async () => {
    if (!selectedContestant || !selectedUser) {
      return;
    }
    setLoading(true);
    const existingContestants = selectedUser?.contestantIds || [];
    const newUser = {
      ...selectedUser,
      contestantIds: existingContestants.concat([selectedContestant.id]),
    };
    await updateUser(newUser);
    props.onClose();
    setLoading(false);
    setSelectedContestant(contestants[0]);
    setSelectedUser(users[0]);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Pair a contestant</DialogTitle>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <DialogContent>
            <FormControl fullWidth variant="filled">
              <InputLabel id="pair-user">User</InputLabel>
              <Select labelId="pair-user" value={selectedUser} label="User" onChange={handleUserChange}>
                {users.map((user) => (
                  <MenuItem value={user as any} key={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled">
              <InputLabel id="pair-contestant">Contestant</InputLabel>
              <Select
                labelId="pair-user"
                value={selectedContestant}
                label="Contestant"
                onChange={handleContestantChange}
              >
                {contestants.map((contestant) => (
                  <MenuItem value={contestant as any} key={contestant.id}>
                    {contestant.firstName} {contestant.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => props.onClose()}>
              Cancel
            </Button>
            <Button onClick={pair} variant="contained">
              Pair
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
