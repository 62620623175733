import styled from '@emotion/styled';
import PersonIcon from '@mui/icons-material/PersonRounded';
import { Button, ButtonGroup } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export const UserSettingsModal = () => {
  const { user, signOut } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  return (
    <Container>
      <PersonIcon sx={{ fontSize: 60 }} color="info" />
      <div>{user.displayName}</div>
      <div>({user.email})</div>
      <ButtonGroup orientation="vertical">
        {/* <Button>Something</Button> */}
        <Button color="error" onClick={signOut}>
          Logout
        </Button>
      </ButtonGroup>
    </Container>
  );
};
