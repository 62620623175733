import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { SelectContestantModal } from '../modals/SelectContestantModal';
import { EliminateModal } from '../modals/EliminateModal';
import { AddPointsModal } from '../modals/AddPointsModal';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';

export const AdminActions = () => {
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isEliminatedModalOpen, setIsEliminatedModalOpen] = useState(false);
  const [isPointsModalOpen, setIsPointsModalOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ padding: '1rem', margin: '1rem' }}>
          <ButtonGroup>
            <Button onClick={() => setIsSelectModalOpen(true)}>Select contestant</Button>
            <Button onClick={() => setIsEliminatedModalOpen(true)}>Eliminate a player</Button>
            <Button onClick={() => setIsPointsModalOpen(true)}>Add points</Button>
          </ButtonGroup>
          {/* Modals */}
        </Paper>
      </Box>
      <SelectContestantModal open={isSelectModalOpen} onClose={() => setIsSelectModalOpen(false)} />
      <EliminateModal open={isEliminatedModalOpen} onClose={() => setIsEliminatedModalOpen(false)} />
      <AddPointsModal open={isPointsModalOpen} onClose={() => setIsPointsModalOpen(false)} />
    </>
  );
};
