import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React, { useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import { stringAvatar } from '../utils/avatar';
import { ContestantInfo } from './ContestantInfo';

import { UserInfo } from './UserInfo';
export const UserStandings = () => {
  const { aggregatedUsers, undraftedContestants } = useContext(DataContext);

  return (
    <List>
      {aggregatedUsers.map((user, index) => {
        return (
          <React.Fragment key={user.id}>
            {index > 0 && <Divider component="li" />}
            <UserInfo user={user} />
            <List component="div" disablePadding>
              {user.contestants.map((contestant) => (
                <ContestantInfo contestant={contestant} key={contestant.id} />
              ))}
            </List>
          </React.Fragment>
        );
      })}
      <Divider component="li" />
      <ListItem alignItems="flex-start" sx={{ alignItems: 'center' }}>
        <ListItemAvatar>
          <Avatar {...stringAvatar('Undrafted Players')} />
        </ListItemAvatar>
        <ListItemText primary="Undrafted Players" />
      </ListItem>
      <List component="div" disablePadding>
        {undraftedContestants.map((contestant) => (
          <ContestantInfo contestant={contestant} key={contestant.id} />
        ))}
      </List>
    </List>
  );
};
